import React, { useState, useContext } from 'react'
import debounce from "lodash.debounce"
import { getShopifyImage } from "gatsby-source-shopify"
import { GatsbyImage } from 'gatsby-plugin-image'
import { StoreContext } from "../../context/storeContext"
import Spinner from '../utils/spinner'


const ProductRow = ({ item }) => {
    const {
        removeLineItem,
        checkout,
        updateLineItem,
        loading,
    } = useContext(StoreContext)

    const [quantity, setQuantity] = useState(item.quantity)

    const variantImage = {
        ...item.variant.image,
        originalSrc: item.variant.image.src,
    }
    const price = item.variant.price

    const subtotal = item.variant.price * quantity

    const handleRemove = () => {
        removeLineItem(checkout.id, item.id)
    }

    const uli = debounce(
        (value) => updateLineItem(checkout.id, item.id, value),
        300
    )
    // eslint-disable-next-line
    const debouncedUli = React.useCallback((value) => uli(value), [])

    const handleQuantityChange = (value) => {
        if (value !== "" && Number(value) < 1) {
            return
        }
        setQuantity(value)
        if (Number(value) >= 1) {
            debouncedUli(value)
        }
    }

    function incrementQuantity() {
        handleQuantityChange(Number(quantity || 0) + 1)
    }

    function decrementQuantity() {
        handleQuantityChange(Number(quantity || 0) - 1)
    }

    const image = React.useMemo(
        () =>
            getShopifyImage({
                image: variantImage,
                layout: "constrained",
                crop: "contain",
                width: 160,
                height: 160,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [variantImage.src]
    )
    return (
        <div>
            <div className='flex justify-between text-left'>
                <div className='h-48 flex'>
                    {image && (
                        <GatsbyImage
                            key={variantImage.src}
                            image={image}
                            alt={variantImage.altText ?? item.variant.title}
                        />
                    )}
                    <div className='pl-5'>
                        <div>
                            <p className='text-xl'><a href={`/products/${item.customAttributes[0].value}`}>{item.title}</a></p>
                            <p>£{price}</p>
                            <p>{item.variant.title === "Default Title" ? "" : item.variant.title}</p>
                        </div>
                        <div className='flex flex-row h-10 w-32 relative bg-transparent mt-1 border border-gray-400'>
                            <button className='bg-transparent h-full w-20 cursor-pointer outline-none flex items-center justify-center' onClick={decrementQuantity}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M20 12H4" />
                                </svg>
                            </button>
                            <input value={quantity} readOnly className="outline-none focus:outline-none text-center bg-transparent font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 w-full" />
                            <button className='bg-transparent h-full w-20 cursor-pointer flex items-center' onClick={incrementQuantity}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 4v16m8-8H4" />
                                </svg>
                            </button>
                        </div>
                        <button className='pt-2' onClick={handleRemove}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className='text-right text-2xl text-text'>
                    {loading ? <Spinner color="#000" /> : `£${subtotal.toFixed(2)}`}
                </div>
            </div>
            <hr className='my-5 w-full border-t border-gray-300' />
        </div>
    )
}

export default ProductRow
