import React from 'react'

const Spinner = ({ color }) => {
    return (
        <svg className='animate-spin inline' width="25" height="25" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={color}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="1">
                    <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                    </path>
                </g>
            </g>
        </svg>
    )
}

export default Spinner
