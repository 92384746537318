import React from 'react'
import { Link } from 'gatsby'
import { StoreContext } from "../context/storeContext"

import Layout from '../components/layout/layout'
import ProductRow from '../components/cart/productRow'
import Spinner from '../components/utils/spinner'
import Seo from '../components/utils/seo'

const Cart = () => {
    const { checkout, loading } = React.useContext(StoreContext)
    const emptyCart = checkout.lineItems.length === 0
    let initialValue = 0.00

    let subtotal = checkout.lineItems.reduce(function (previousValue, currentValue) {
        console.log(previousValue, currentValue)
        return previousValue + currentValue.variant.price * currentValue.quantity
    }, initialValue)

    const handleCheckout = () => {
        window.open(checkout.webUrl)
    }
    return (
        <Layout>
            <Seo title="Cart">
                <meta name="robots" content="noindex" />
            </Seo>
            <div className='font-3xl text-center my-5'>
                <h1>Your Cart</h1>
                {emptyCart ? (
                    <div className='mt-8 px-4 max-w-screen-lg mx-auto'>
                        <h2 className='text-3xl'>Your cart is empty</h2>
                        <Link to="/herbal-products-plants" className='block bg-accent-1 text-text-light px-5 py-2 my-5 hover:shadow-lg shadow-accent-1 w-max mx-auto'>Continue shopping</Link>
                    </div>
                ) : (
                    <div className='px-4 max-w-screen-lg mx-auto'>

                        <div className='w-full'>
                            <div>
                                <div className='flex justify-between w-full text-gray-600'>
                                    <div>Product</div>
                                    <div>Total</div>
                                </div>
                                <hr className='my-5 w-full border-t border-gray-300' />
                            </div>
                            <div>
                                {checkout.lineItems.map((item) => (
                                    <ProductRow item={item} key={item.id} />
                                ))}
                            </div>
                        </div>
                        <div className='md:text-right'>
                            <p className='text-2xl'>Subtotal:{loading ? <Spinner color="#000" /> : <span>£{subtotal.toFixed(2)}</span>}</p>
                            <small>Tax included and shipping calculated at checkout</small>
                        </div>
                        <div className='text-right'>
                            <button className='bg-accent-1 text-text-light px-5 py-2 my-5 hover:shadow-lg shadow-accent-1 w-full md:w-48' onClick={handleCheckout} disabled={loading}>Checkout</button>
                        </div>
                    </div>
                )}
            </div>
            <form>


            </form>

        </Layout>
    )
}

export default Cart
